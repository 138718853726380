import { Modal, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Swal from "sweetalert2";
import { AuthContext } from "../../auth/AuthContext";
import { useParams } from "react-router";
import { baseURL } from "../../baseURL";
const ModalAgregarRegistro = ({ peticionGet, data, areaId, cliente }) => {
  const { user } = useContext(AuthContext);
  const usuario = user[0];
  const hoy = new Date();
  const { IdArea } = useParams();
  const mes =
    hoy.getMonth().length > 1 ? hoy.getMonth() + 1 : "0" + (hoy.getMonth() + 1);
  const fecha = hoy.getFullYear() + "-" + mes + "-" + hoy.getDate();
  const hora = hoy.getHours() + ":" + hoy.getMinutes() + ":" + hoy.getSeconds();
  const fechaYHora = fecha + "T" + hora;

  const useStyles = makeStyles((theme) => ({
    modal: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    iconos: {
      cursor: "pointer",
    },
    inputMaterial: {
      width: "100%",
    },
  }));
  const styles = useStyles();
  const [modalInsertar, setModalInsertar] = useState(false);
  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    IdRegistro: "",
    FechaRegistro: fechaYHora,
    IdArea: areaId,
    IdUsuario: usuario.ID_USUARIO,
    IdCliente: "",
    IngredienteActivo: "CO-SUPER-Q",
    Concentracion: "0,5%",
    RegistroSanitario: "D-1020 / 20",
    ModoAccion: "Contacto",
    Formulacion: "Amonio Cuaternario de Quinta Generación ",
  });

  const getArea = async () => {
    axios.get(baseURL + "areas.php?IdArea=" + IdArea).then((response) => {
      if (response.data.length === 0) {
      } else {
        setConsolaSeleccionada((prevState) => ({
          ...prevState,
          IdCliente: response.data[0].IdCliente,
        }));
      }
    });
  };
  getArea();
  const peticionPost = async (e) => {
    e.preventDefault();
    axios
      .post(baseURL + "registro.php", consolaSeleccionada)
      .then((response) => {
        console.log(response);
        peticionGet();
      })
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Cliente agregado correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      });
    abrirCerrarModalInsertar();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setConsolaSeleccionada((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(consolaSeleccionada);
  };
  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };
  const body = (
    <div className={styles.modal}>
      <h3>Agregar Registro</h3>
      <form onSubmit={peticionPost}>
        <TextField
          name="FechaRegistro"
          label="Fecha Registro"
          className={styles.inputMaterial}
          onChange={handleChange}
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          value={consolaSeleccionada.FechaRegistro}
        />
        <TextField
          name="IngredienteActivo"
          label="Ingrediente Activo"
          className={styles.inputMaterial}
          defaultValue={consolaSeleccionada.IngredienteActivo}
          onChange={handleChange}
        />

        <TextField
          name="Concentracion"
          label="Concentracion"
          className={styles.inputMaterial}
          onChange={handleChange}
          defaultValue={consolaSeleccionada.Concentracion}
        />
        <TextField
          name="RegistroSanitario"
          label="Registro Sanitario"
          className={styles.inputMaterial}
          onChange={handleChange}
          defaultValue={consolaSeleccionada.RegistroSanitario}
        />

        <TextField
          name="ModoAccion"
          label="Modo Acción"
          className={styles.inputMaterial}
          onChange={handleChange}
          defaultValue={consolaSeleccionada.ModoAccion}
        />

        <TextField
          name="Formulacion"
          label="Formulacion"
          className={styles.inputMaterial}
          defaultValue={consolaSeleccionada.Formulacion}
          onChange={handleChange}
        />
        <div align="right">
          <Button color="primary" type="submit" /* onClick={peticionPost} */>
            Agregar
          </Button>
          <Button onClick={abrirCerrarModalInsertar}>Cancelar</Button>
        </div>
      </form>
    </div>
  );
  return (
    <div>
      <Button onClick={abrirCerrarModalInsertar}>
        <AddCircleIcon /> Nuevo Registro
      </Button>
      <Modal open={modalInsertar} onClose={abrirCerrarModalInsertar}>
        {body}
      </Modal>
    </div>
  );
};

export default ModalAgregarRegistro;
