import React, { useReducer } from "react";
import AppRouter from "./routers/AppRouter";
import { AuthContext } from "./auth/AuthContext";
import { authReducer } from "./auth/authReducer";
import { useEffect } from "react";
import "./App.css"
const init = () => {
  return JSON.parse(localStorage.getItem("user")) || { logged: false };
};

export const SistemaApp = () => {
  const [user, dispatch] = useReducer(authReducer, {}, init);
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);
  return (
    <AuthContext.Provider value={{ user, dispatch }}>
      <AppRouter />
    </AuthContext.Provider>
  );
};
