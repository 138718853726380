import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export const PublicRoute = ({
    isAuthenticated,
    component: Componenet,
    ...rest
}) => {
    return (
       <Route {...rest}
            component={(props) =>(
                (!isAuthenticated)
                ? (<Componenet {...props} />)
                : (<Redirect to="/" />)
            )}
       />
    )
}
