import { Modal, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Swal from "sweetalert2";
import { baseURL } from "../../baseURL";
import { FormControl } from "@material-ui/core";
import { format, validate } from "rut.js";

const ModalAgregarArea = ({ peticionGet, clienteId }) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    iconos: {
      cursor: "pointer",
    },
    inputMaterial: {
      width: "100%",
    },
  }));
  const styles = useStyles();
  const [modalInsertar, setModalInsertar] = useState(false);

  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    NombreArea: "",
    IdCliente: clienteId
  });

  const peticionPost = async (e) => {
    e.preventDefault();
    axios.post(baseURL + "areas.php", consolaSeleccionada).then((response) => {
      peticionGet(clienteId);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Cliente agregado correctamente",
        showConfirmButton: false,
        timer: 1500,
      });
      abrirCerrarModalInsertar();
    });
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setConsolaSeleccionada((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(consolaSeleccionada);
  };
  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };

  const body = (
    <div className={styles.modal}>
      <h3>Registrar Area</h3>
      <form onSubmit={peticionPost}>
        <TextField
          name="NombreArea"
          label="Nombre Area"
          className={styles.inputMaterial}
          onChange={handleChange}
          required
        />

        <div align="right">
          <Button type="submit" color="primary">
            Insertar
          </Button>
          <Button onClick={abrirCerrarModalInsertar}>Cancelar</Button>
        </div>
      </form>
    </div>
  );
  return (
    <div>
      <Button onClick={abrirCerrarModalInsertar}>
        <AddCircleIcon /> Registrar Area
      </Button>
      <Modal open={modalInsertar} onClose={abrirCerrarModalInsertar}>
        {body}
      </Modal>
    </div>
  );
};

export default ModalAgregarArea;
