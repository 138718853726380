import React, { useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ModalAgregarRegistro from "./ModalAgregarRegistro";
import ModalEditarRegistro from "./ModalEditarRegistro";
import { baseURL } from "../../baseURL";
import { AuthContext } from "../../auth/AuthContext";
import Swal from "sweetalert2";
const ListaRegistros = ({ areaId, nombre, cliente }) => {
  const [data, setData] = useState([]);
  const { user } = useContext(AuthContext);
  const [registroSeleccionado, setRegistroSeleccionado] = useState({
    IdRegistro: "",
    FechaRegistro: "",
    IdArea:"",
    IdUsuario: "",
    IdCliente: "",
    IngredienteActivo: "",
    Concentracion: "",
    RegistroSanitario: "",
    ModoAccion: "",
    Formulacion: "",
  });
  const editarRegistro = async (event, rowData) => {
    axios
      .get(baseURL + "registro.php?obtenerIdRegistro=" + rowData.IdRegistro)
      .then((response) => {
        console.log(response)
        if (response.data.length === 0) {
          setRegistroSeleccionado([]);
        } else {
          response.data[0].FechaRegistro = response.data[0].FechaRegistro.replace(/\s/g, "T");
          setRegistroSeleccionado(response.data[0]);
        }
      })
      .then(() => {
        document.querySelector("#botonModal").click();
      });
  };

  const peticionGet = async () => {
    axios.get(baseURL + "registro.php?areaId=" + areaId).then((response) => {
      if (response.data.length === 0) {
        setData([]);
      } else {
        setData(response.data);
      }
    });
  };

  useEffect(async () => {
    await peticionGet();
  }, []);

  const history = useHistory();
  const verRegistro = (event, rowData) => {
    history.push("/registro/" + rowData.IdRegistro);
  };

  const eliminarRegistro = async (event, rowData) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "El registro " + rowData.FechaRegistro + " sera eliminado",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            baseURL + "registro.php?IdRegistro=" + Number(rowData.IdRegistro)
          )
          .then(() => {
            peticionGet().then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Cliente eliminado correctamente",
                showConfirmButton: false,
                timer: 1500,
              });
            });
          });
      }
    });
  };

  const imprimirRegistro = (event, rowData) => {};
  const columns = [
    {
      title: "Registro Sanitario",
      field: "RegistroSanitario",
    },
    {
      title: "Fecha Registro",
      field: "FechaRegistro",
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-end">
        <ModalAgregarRegistro
          peticionGet={peticionGet}
          setData={setData}
          data={data}
          areaId={areaId}
          cliente={cliente}
        />
      </div>

      <ModalEditarRegistro
        peticionGet={peticionGet}
        registroSeleccionado={registroSeleccionado}
        setRegistroSeleccionado={setRegistroSeleccionado}
      />
      <div className="mt-3">
        {/* tabla de fichas */}
        <MaterialTable
          columns={columns}
          data={data}
          title={nombre}
          actions={
            Number(user[0].FK_ID_TIPO_USUARIO) == 3
              ? [
                  {
                    icon: "visibility",
                    tooltip: "Ver Registro",
                    onClick: (event, rowData) => verRegistro(event, rowData),
                  },
                  {
                    icon: "edit",
                    tooltip: "Editar Registro",
                    onClick: (event, rowData) => editarRegistro(event, rowData),
                  },
                  {
                    icon: "delete",
                    tooltip: "Eliminar Registro",
                    onClick: (event, rowData) =>
                      eliminarRegistro(event, rowData),
                  },
                ]
              : [
                  {
                    icon: "visibility",
                    tooltip: "Ver Registro",
                    onClick: (event, rowData) => verRegistro(event, rowData),
                  },
                ]
          }
          options={{
            actionsColumnIndex: -1,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "No hay datos por mostrar",
              addTooltip: "Añadir",
              deleteTooltip: "Eliminar",
              editTooltip: "Editar",
              filterRow: {
                filterTooltip: "Filtrar",
              },
              editRow: {
                deleteText: "¿Segura(o) que quiere eliminar?",
                cancelTooltip: "Cancelar",
                saveTooltip: "Guardar",
              },
            },
            grouping: {
              placeholder: "Arrastre un encabezado aquí para agrupar",
              groupedBy: "Agrupado por",
            },
            header: {
              actions: "Acciones",
            },
            pagination: {
              firstAriaLabel: "Primera página",
              firstTooltip: "Primera página",
              labelDisplayedRows: "{from}-{to} de {count}",
              labelRowsPerPage: "Filas por página:",
              labelRowsSelect: "filas",
              lastAriaLabel: "Ultima página",
              lastTooltip: "Ultima página",
              nextAriaLabel: "Pagina siguiente",
              nextTooltip: "Pagina siguiente",
              previousAriaLabel: "Pagina anterior",
              previousTooltip: "Pagina anterior",
            },
            toolbar: {
              addRemoveColumns: "Agregar o eliminar columnas",
              exportAriaLabel: "Exportar",
              exportName: "Exportar a CSV",
              exportTitle: "Exportar",
              nRowsSelected: "{0} filas seleccionadas",
              searchPlaceholder: "Buscar",
              searchTooltip: "Buscar",
              showColumnsAriaLabel: "Mostrar columnas",
              showColumnsTitle: "Mostrar columnas",
            },
          }}
        />
      </div>
    </>
  );
};

export default ListaRegistros;
