import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ClienteScreen from "../components/cliente/ClienteScreen";
import { Navbar } from "../components/ui/Navbar";
import { Home } from "../components/ui/Home";
import LoginScreen from "../components/login/LoginScreen";
import ClienteScreenSinLog from "../components/cliente/ClienteScreenSinLog";
import AreaScreenSinLogin from "../components/area/AreaScreenSinLog";

const RutasPublicas = () => {
  return (
    <div>
      <div className="container animate__animated animate__fadeIn">
        <Switch>
          <Route exact path="/area/:IdArea" component={AreaScreenSinLogin} />
          <Route exact path="/login" component={LoginScreen} />
          <Redirect to="/login" />
        </Switch>
      </div>
    </div>
  );
};

export default RutasPublicas;
