import React from "react";

export const Home = () => {
  return (
    <div className="row mt-5">
      <div className="col-12 col-md-5">
        <div class="card text-white bg-dark mb-3">
          <div class="card-body">
            <div className="card-title">
              <h1>Bienvenido a nuestro sistema de registro</h1>
            </div>
            <p class="card-text">
              <h1></h1>
              <p>
                Para generar un nuevo registro debes escanear el codigo QR del
                cliente
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
