import { Modal, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Swal from "sweetalert2";
import { baseURL } from "../../baseURL";
import { FormControl } from "@material-ui/core";
import { format, validate } from "rut.js";

const ModalAgregarCliente = ({ peticionGet }) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    iconos: {
      cursor: "pointer",
    },
    inputMaterial: {
      width: "100%",
    },
  }));
  const styles = useStyles();
  const [modalInsertar, setModalInsertar] = useState(false);

  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    RazonSocial: "",
    Representante: "",
    RutCliente: "",
    Giro: "",
    DireccionCliente: "",
    TelefonoCliente: "",
    EmailCliente: "",
  });

  const peticionPost = async (e) => {
    e.preventDefault();

    await axios
      .get(baseURL + "cliente.php?RutCliente=" + consolaSeleccionada.RutCliente)
      .then((response) => {
        if (Number(response.data[0].filas) > 0) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "El Rut ya esta registrado",
            showConfirmButton: false,
            timer: 1500,
          });
          abrirCerrarModalInsertar();
        } else {
          axios
            .post(baseURL + "cliente.php", consolaSeleccionada)
            .then(() => {
              peticionGet();
            })
            .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Cliente agregado correctamente",
                showConfirmButton: false,
                timer: 1500,
              });
            });
          abrirCerrarModalInsertar();
        }
      });
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    if (name == "RutCliente") {
      setConsolaSeleccionada((prevState) => ({
        ...prevState,
        [name]: format(value),
      }));
      e.target.value = format(value);
    } else {
      setConsolaSeleccionada((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };

  const body = (
    <div className={styles.modal}>
      <h3>Registrar Cliente</h3>
      <form onSubmit={peticionPost}>
        <TextField
          name="RazonSocial"
          label="Razón Social"
          className={styles.inputMaterial}
          onChange={handleChange}
        />
        <TextField
          name="Representante"
          label="Representante Legal"
          className={styles.inputMaterial}
          onChange={handleChange}
        />
        <TextField
          name="RutCliente"
          label="Rut Cliente"
          className={styles.inputMaterial}
          onChange={handleChange}
        />
        <TextField
          name="Giro"
          label="Giro"
          className={styles.inputMaterial}
          onChange={handleChange}
        />
        <TextField
          name="DireccionCliente"
          label="Dirección"
          className={styles.inputMaterial}
          onChange={handleChange}
        />
        <TextField
          name="TelefonoCliente"
          label="Teléfono"
          className={styles.inputMaterial}
          onChange={handleChange}
          type="tel"
        />
        <TextField
          name="EmailCliente"
          label="Email"
          className={styles.inputMaterial}
          onChange={handleChange}
          type="email"
        />

        <div align="right">
          <Button type="submit" color="primary">
            Insertar
          </Button>
          <Button onClick={abrirCerrarModalInsertar}>Cancelar</Button>
        </div>
      </form>
    </div>
  );
  return (
    <div>
      <Button onClick={abrirCerrarModalInsertar}>
        <AddCircleIcon /> Registrar Cliente
      </Button>
      <Modal open={modalInsertar} onClose={abrirCerrarModalInsertar}>
        {body}
      </Modal>
    </div>
  );
};

export default ModalAgregarCliente;
