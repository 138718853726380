import React, { useContext, useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import MaterialTable from "material-table";
import axios from "axios";
import { AuthContext } from "../../auth/AuthContext";
import ModalAgregarCliente from "./ModalAgregarCliente";
import Swal from "sweetalert2";
import { baseURL } from "../../baseURL";
import ModalEditarCliente from "./ModalEditarCliente";
const ListaClientes = ({ publisher }) => {
  const [clienteSeleccionado, setClienteSeleccionado] = useState({
    IdCliente: "",
    RutCliente: "",
    Giro: "",
    DireccionCliente: "",
    TelefonoCliente: "",
    EmailCliente: "",
    Representante: "",
    RazonSocial: "",
  });
  const editarCliente = async (event, rowData) => {

    axios
      .get(baseURL + "clienteid.php?IdCliente=" + rowData.IdCliente)
      .then((response) => {
        if (response.data.length === 0) {
          setClienteSeleccionado("nodatos");
        } else {
          setClienteSeleccionado(response.data[0]);
        }
      })
      .then(() => {
        document.querySelector("#botonModal").click();
      });
  };

  const [data, setData] = useState([]);
  const peticionGet = async () => {
    axios.get(baseURL + "listarclientes.php").then((response) => {
      if (response.data.length === 0) {
        setData([]);
      } else {
        setData(response.data);
      }
    });
  };

  const peticionDelete = async (IdCliente) => {
    axios.get(baseURL + "cliente.php?eliminar=" + IdCliente).then(()=>{
      peticionGet();
    });
  };

  const eliminarCliente = async (event, rowData) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "El cliente " + rowData.RutCliente + " sera eliminado",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        peticionDelete(rowData.IdCliente);
        peticionGet().then(() => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Cliente eliminado correctamente",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      }
    });
  };

  useEffect(async () => {
    await peticionGet();
  }, []);

  const { user } = useContext(AuthContext);
  const history = useHistory();

  if (user[0].FK_ID_TIPO_USUARIO !== "3") {
    return <Redirect to="/" />;
  }

  const verCliente = (event, rowData) => {
    history.push("/cliente/" + rowData.IdCliente);
  };

  const columns = [
    {
      title: "Rut Cliente",
      field: "RutCliente",
    },
    {
      title: "Razon Social",
      field: "RazonSocial",
    },
    {
      title: "Giro",
      field: "Giro",
    },
  ];

  return (
    <div className="mt-3">
      <div
        className="d-flex justify-content-end align-items-center"
        align="end"
      >
        {user[0].FK_ID_TIPO_USUARIO !== 3 ? (
          <ModalAgregarCliente peticionGet={peticionGet} setData={setData} />
        ) : null}
      </div>

      <ModalEditarCliente
        peticionGet={peticionGet}
        clienteSeleccionado={clienteSeleccionado}
        setClienteSeleccionado={setClienteSeleccionado}
      />
      <div className="row">
        <div className="col">
          <MaterialTable
            columns={columns}
            data={data}
            title="Lista de Clientes"
            actions={[
              {
                icon: "visibility",
                tooltip: "Ver Cliente",
                onClick: (event, rowData) => verCliente(event, rowData),
              },
              {
                icon: "edit",
                tooltip: "Editar Cliente",
                onClick: (event, rowData) => editarCliente(event, rowData),
              },
              {
                icon: "delete",
                tooltip: "Eliminar Cliente",
                onClick: (event, rowData) => eliminarCliente(event, rowData),
              },
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "No hay datos por mostrar",
                addTooltip: "Añadir",
                deleteTooltip: "Eliminar",
                editTooltip: "Editar",
                filterRow: {
                  filterTooltip: "Filtrar",
                },
                editRow: {
                  deleteText: "¿Segura(o) que quiere eliminar?",
                  cancelTooltip: "Cancelar",
                  saveTooltip: "Guardar",
                },
              },
              grouping: {
                placeholder: "Arrastre un encabezado aquí para agrupar",
                groupedBy: "Agrupado por",
              },
              header: {
                actions: "Acciones",
              },
              pagination: {
                firstAriaLabel: "Primera página",
                firstTooltip: "Primera página",
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsPerPage: "Filas por página:",
                labelRowsSelect: "filas",
                lastAriaLabel: "Ultima página",
                lastTooltip: "Ultima página",
                nextAriaLabel: "Pagina siguiente",
                nextTooltip: "Pagina siguiente",
                previousAriaLabel: "Pagina anterior",
                previousTooltip: "Pagina anterior",
              },
              toolbar: {
                addRemoveColumns: "Agregar o eliminar columnas",
                exportAriaLabel: "Exportar",
                exportName: "Exportar a CSV",
                exportTitle: "Exportar",
                nRowsSelected: "{0} filas seleccionadas",
                searchPlaceholder: "Buscar",
                searchTooltip: "Buscar",
                showColumnsAriaLabel: "Mostrar columnas",
                showColumnsTitle: "Mostrar columnas",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ListaClientes;
