import React from "react";
import { Navbar } from "../components/ui/Navbar";
import { Switch, Route, Redirect } from "react-router-dom";
import ListaClientes from "../components/cliente/ListaClientes";
import ClienteScreen from "../components/cliente/ClienteScreen";
import ListaTareas from "../components/tarea/ListaTareas";
import TareaScreen from "../components/tarea/TareaScreen";
import { Home } from "../components/ui/Home";
import RegistroScreen from "../components/registro/RegistroScreen";
import ListaUsuarios from "../components/usuario/ListaUsuarios";
import AreaScreen from "../components/area/AreaScreen";

export const DashboardRoutes = () => {
  return (
    <>
      <Navbar />

      <div className="container animate__animated animate__fadeIn">
        <Switch>
          <Route exact path="/clientes" component={ListaClientes} />
          <Route exact path="/cliente/:clienteId" component={ClienteScreen} />
          <Route exact path="/registro/:registroId" component={RegistroScreen} />
          <Route exact path="/area/:IdArea" component={AreaScreen} />


          <Route exact path="/home" component={Home} />
          
          <Redirect to="/home" />
        </Switch>
      </div>
    </>
  );
};
