import React, { useContext, useEffect, useState } from "react";
import { Redirect, useLocation, useParams, useHistory } from "react-router-dom";
import ListaRegistros from "../registro/ListaRegistros";
import QRCode from "qrcode.react";
import axios from "axios";
import ModalAgregarCliente from "./ModalAgregarCliente";
import ModalAgregarRegistro from "../registro/ModalAgregarRegistro";
import { baseURL } from "../../baseURL";
import meis from "../../img/meischile.png";
import { AuthContext } from "../../auth/AuthContext";
import ListaAreas from "../area/ListaAreas";

const ClienteScreen = () => {
  const { user } = useContext(AuthContext);
  const { clienteId } = useParams();
  const { pathname } = useLocation();
  const [modalEditar, setModalEditar] = useState(false);
  const [data, setData] = useState({
    IdRegistro: "",
    AreasTratadas: "",
    FechaRegistro: "",
    IdSanitizacion: "",
    IdCliente: clienteId,
    RegistroSanitario: "",
    Concentracion: "",
    ModoAccion: "",
    Formulacion: "",
    IngredienteActivo: "",
  });

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };
  const history = useHistory();
  const retroceder = () => {
    history.goBack();
  };

  const peticionGetCliente = async () => {
    axios
      .get(baseURL + "clienteid.php?IdCliente=" + clienteId)
      .then((response) => {
        if (response.data.length === 0) {
        } else {
          setData(response.data[0]);
        }
      });
  };
  useEffect(async () => {
    await peticionGetCliente();
  }, []);
  if (!user.FK_ID_TIPO_USUARIO == 3) {
    return <Redirect to="/home" />;
  }
  if (data == "nodatos") {
    return <Redirect to="/clientes" />;
  }

  /* QR */
  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${pathname}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div>
      <div className="row d-flex">
        <div className="col-md-4 mt-3">
          <div class="card text-white bg-dark mb-3">
            <div class="card-body">
              <p class="card-text">
                <strong>Cliente:</strong> {data.RutCliente}
                <br />
                <strong>Razón Social:</strong> {data.RazonSocial}
                <br />
                <strong>Giro:</strong> {data.Giro}
                <br />
                <strong>Email:</strong> {data.EmailCliente}
                <br />
                <strong>Teléfono:</strong>{" "}
                <a href="" className="text-light text-decoration-none">
                  {data.TelefonoCliente}
                </a>
                <br />
                <strong>Representante:</strong> {data.Representante}
              </p>
            </div>
          </div>
          {/* <div className="d-flex align-items-center flex-column">
            <QRCode
              value={"" + window.location.href + ""}
              id="qr-gen"
              size={200}
              level={"H"}
              includeMargin={true}
              imageSettings={{
                src: meis,
                x: null,
                y: null,
                height: 35,
                width: 40,
                excavate: true,
              }}
            />
            <button className="btn btn-light" onClick={downloadQRCode}>
              Descargar Código QR
            </button>
          </div> */}
        </div>
        <div className="col-md-8  mt-3">
          <div className="mt-3">
            {/* lista fichas */}
            <ListaAreas
              clienteId={clienteId}
              abrirCerrarModalEditar={abrirCerrarModalEditar}
            />
          </div>
        </div>
      </div>
      <div className="row"></div>
    </div>
  );
};

export default ClienteScreen;
