import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Navbar } from "../components/ui/Navbar";
import LoginScreen from "../components/login/LoginScreen";
import ListaClientes from "../components/cliente/ListaClientes";
import { DashboardRoutes } from "./DashboardRoutes";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import RutasPublicas from "./RutasPublicas";
import { AuthContext } from "../auth/AuthContext";
const AppRouter = () => {
  const { user } = useContext(AuthContext);
  console.log(user);
  return (
    <Router>
      <div>
        <Switch>
          {user.logged ? (
            <PrivateRoute
              path="/"
              component={DashboardRoutes}
              isAuthenticated={user.logged}
            />
          ) : (
            <PublicRoute
              path="/"
              component={RutasPublicas}
              isAuthenticated={user.logged}
            />
          )}
        </Switch>
      </div>
    </Router>
  );
};

export default AppRouter;
