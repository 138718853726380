import React from "react";
import { useContext } from "react";
import { AuthContext } from "../../auth/AuthContext";
import { types } from "../../types/types";
import SignIn from "./SignIn";
const LoginScreen = ({ history }) => {
  const { dispatch } = useContext(AuthContext);

  return (
    <SignIn dispatch={dispatch} history={history} />
  );
};

export default LoginScreen;
