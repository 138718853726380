import React, { useContext, useEffect, useState } from "react";
import { Redirect, useLocation, useParams, useHistory } from "react-router-dom";
import ListaRegistros from "../registro/ListaRegistros";
import QRCode from "qrcode.react";
import axios from "axios";
import ModalAgregarCliente from "./ModalAgregarArea";
import ModalAgregarRegistro from "../registro/ModalAgregarRegistro";
import { baseURL } from "../../baseURL";
import meis from "../../img/meischile.png";
import { AuthContext } from "../../auth/AuthContext";
const AreaScreen = () => {
  const { user } = useContext(AuthContext);
  const { IdArea } = useParams();
  const { pathname } = useLocation();
  const [modalEditar, setModalEditar] = useState(false);
  const [area, setArea] = useState({
    IdArea: IdArea,
    IdCliente: "",
    NombreArea: "",
  });
  const [registros, setSergistros] = useState([]);
  const [cliente, setCliente] = useState({
    IdCliente: "",
    RutCliente: "",
    Giro: "",
    DireccionCliente: "",
    EmailCliente: "",
    Representante: "",
    RazonSocial: "",
  });

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };
  const history = useHistory();
  const retroceder = () => {
    history.goBack();
  };

  const getArea = async () => {
    axios.get(baseURL + "areas.php?IdArea=" + IdArea).then((response) => {
      if (response.data.length === 0) {
      } else {
        setArea(response.data[0]);
        peticionGetCliente(response.data[0].IdCliente);
      }
    });
  };

  const peticionGetCliente = async (clienteId) => {
    axios
      .get(baseURL + "clienteid.php?IdCliente=" + clienteId)
      .then((response) => {
        if (response.data.length === 0) {
        } else {
          setCliente(response.data[0]);
        }
      });
  };

  useEffect(async () => {
    await getArea();
    /*  await peticionGetCliente(); */
  }, []);

  /* QR */
  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${pathname}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div>
      <div className="row d-flex">
        <div className="col-md-4 mt-3">
          <div class="card text-white bg-dark mb-3">
            <div class="card-body">
              <p class="card-text">
                <strong>Cliente:</strong> {cliente.RutCliente}
                <br />
                <strong>Razón Social:</strong> {cliente.RazonSocial}
                <br />
                <strong>Giro:</strong> {cliente.Giro}
                <br />
                <strong>Email:</strong> {cliente.EmailCliente}
                <br />
                <strong>Teléfono:</strong>{" "}
                <a href="" className="text-light text-decoration-none">
                  {cliente.TelefonoCliente}
                </a>
                <br />
                <strong>Representante:</strong> {cliente.Representante}
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center flex-column">
            <QRCode
              value={"" + window.location.href + ""}
              id="qr-gen"
              size={200}
              level={"H"}
              includeMargin={true}
              imageSettings={{
                src: meis,
                x: null,
                y: null,
                height: 35,
                width: 40,
                excavate: true,
              }}
            />
            <button className="btn btn-light" onClick={downloadQRCode}>
              Descargar Código QR
            </button>
          </div>
        </div>
        <div className="col-md-8  mt-3">
          <div className="mt-3">
            {/* lista fichas */}

            <ListaRegistros
              nombre={area.NombreArea}
              areaId={area.IdArea}
              registros={registros}
              cliente={cliente}
              setCliente={setCliente}
              abrirCerrarModalEditar={abrirCerrarModalEditar}
            />
          </div>
        </div>
      </div>
      <div className="row"></div>
    </div>
  );
};

export default AreaScreen;
