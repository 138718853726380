import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export const PrivateRoute = ({
    isAuthenticated,
    component: Componenet,
    ...rest
}) => {

    localStorage.setItem('lastPath',rest.location.pathname);
    return (
       <Route {...rest}
            component={(props) =>(
                (isAuthenticated)
                ? (<Componenet {...props} />)
                : (<Redirect to="/login" />)
            )}
       />
    )
}
