import React, { useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ModalAgregarArea from "./ModalAgregarArea";
import ModalEditarArea from "./ModalEditarArea";
import { baseURL } from "../../baseURL";
import { AuthContext } from "../../auth/AuthContext";
import Swal from "sweetalert2";
const ListaAreas = ({ clienteId }) => {
  const [areas, setAreas] = useState([]);
  const { user } = useContext(AuthContext);
  const [areaSeleccionada, setAreaSeleccionada] = useState({
    IdArea: "",
    IdCliente: clienteId,
    NombreArea: "",
  });

  const editarArea = async (event, rowData) => {
    axios
      .get(baseURL + "areas.php?IdArea=" + rowData.IdArea)
      .then((response) => {
        if (response.data.length === 0) {
          setAreaSeleccionada([]);
        } else {
          setAreaSeleccionada(response.data[0]);
        }
      })
      .then(() => {
        document.querySelector("#botonModal").click();
      });
  };

  const peticionGet = async (clienteId) => {
    axios.get(baseURL + "areas.php?IdCliente=" + clienteId).then((response) => {
      if (response.data.length === 0) {
        setAreas([]);
      } else {
        setAreas(response.data);
      }
    });
  };

  useEffect(async () => {
    await peticionGet(clienteId);
  }, []);

  const history = useHistory();
  const verArea = (event, rowData) => {
    history.push("/area/" + rowData.IdArea);
  };

  const eliminarArea = async (event, rowData) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "El Area " + rowData.NombreArea + " sera eliminado",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(baseURL + "areas.php?IdArea=" + rowData.IdArea)
          .then((response) => {
            peticionGet(clienteId).then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Area eliminado correctamente",
                showConfirmButton: false,
                timer: 1500,
              });
            });
          });
      }
    });
  };

  const columns = [
    {
      title: "Nombre Area",
      field: "NombreArea",
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-end">
        <ModalAgregarArea peticionGet={peticionGet} clienteId={clienteId} />
      </div>

      <ModalEditarArea
        peticionGet={peticionGet}
        areaSeleccionada={areaSeleccionada}
        setAreaSeleccionada={setAreaSeleccionada}
        clienteId={clienteId}
      />
      <div className="mt-3">
        {/* tabla de fichas */}
        <MaterialTable
          columns={columns}
          data={areas}
          title="Areas"
          actions={
            Number(user[0].FK_ID_TIPO_USUARIO) == 3
              ? [
                  {
                    icon: "visibility",
                    tooltip: "Ver Area",
                    onClick: (event, rowData) => verArea(event, rowData),
                  },
                  {
                    icon: "edit",
                    tooltip: "Editar Area",
                    onClick: (event, rowData) => editarArea(event, rowData),
                  },
                  {
                    icon: "delete",
                    tooltip: "Eliminar Area",
                    onClick: (event, rowData) => eliminarArea(event, rowData),
                  },
                ]
              : [
                  {
                    icon: "visibility",
                    tooltip: "Ver Area",
                    onClick: (event, rowData) => verArea(event, rowData),
                  },
                ]
          }
          options={{
            actionsColumnIndex: -1,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "No hay datos por mostrar",
              addTooltip: "Añadir",
              deleteTooltip: "Eliminar",
              editTooltip: "Editar",
              filterRow: {
                filterTooltip: "Filtrar",
              },
              editRow: {
                deleteText: "¿Segura(o) que quiere eliminar?",
                cancelTooltip: "Cancelar",
                saveTooltip: "Guardar",
              },
            },
            grouping: {
              placeholder: "Arrastre un encabezado aquí para agrupar",
              groupedBy: "Agrupado por",
            },
            header: {
              actions: "Acciones",
            },
            pagination: {
              firstAriaLabel: "Primera página",
              firstTooltip: "Primera página",
              labelDisplayedRows: "{from}-{to} de {count}",
              labelRowsPerPage: "Filas por página:",
              labelRowsSelect: "filas",
              lastAriaLabel: "Ultima página",
              lastTooltip: "Ultima página",
              nextAriaLabel: "Pagina siguiente",
              nextTooltip: "Pagina siguiente",
              previousAriaLabel: "Pagina anterior",
              previousTooltip: "Pagina anterior",
            },
            toolbar: {
              addRemoveColumns: "Agregar o eliminar columnas",
              exportAriaLabel: "Exportar",
              exportName: "Exportar a CSV",
              exportTitle: "Exportar",
              nRowsSelected: "{0} filas seleccionadas",
              searchPlaceholder: "Buscar",
              searchTooltip: "Buscar",
              showColumnsAriaLabel: "Mostrar columnas",
              showColumnsTitle: "Mostrar columnas",
            },
          }}
        />
      </div>
    </>
  );
};

export default ListaAreas;
