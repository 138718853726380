import { Modal, Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Swal from "sweetalert2";
import { baseURL } from "../../baseURL";
import { FormControl } from "@material-ui/core";
import { format, validate } from "rut.js";

const ModalEditarArea = ({
  peticionGet,
  areaSeleccionada,
  setAreaSeleccionada,
  clienteId,
}) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    iconos: {
      cursor: "pointer",
    },
    inputMaterial: {
      width: "100%",
    },
  }));
  const styles = useStyles();
  const [modalEditar, setModalEditar] = useState(false);

  /*   const peticionPost = async (e) => {
    e.preventDefault();

    await axios
      .get(baseURL + "cliente.php?RutCliente=" + consolaSeleccionada.RutCliente)
      .then((response) => {
        if (Number(response.data[0].filas) > 0) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "El Rut ya esta registrado",
            showConfirmButton: false,
            timer: 1500,
          });
          abrirCerrarModalEditar();
        } else {
          axios
            .post(baseURL + "cliente.php", consolaSeleccionada)
            .then((response) => console.log(response))
            .then(() => {
              peticionGet();
            })
            .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Cliente agregado correctamente",
                showConfirmButton: false,
                timer: 1500,
              });
            });
          abrirCerrarModalEditar();
        }
      });
  }; */

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setAreaSeleccionada((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };

  const enviarDatos = async (e) => {
    e.preventDefault();
    axios
      .put(baseURL + "areas.php", areaSeleccionada)
      .then((response) => {
        console.log(response)
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Cliente modificado correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
        abrirCerrarModalEditar();
      })
      .then(() => {
        peticionGet(clienteId);
      });
  };

  const body = (
    <div className={styles.modal}>
      <h3>Editar Area</h3>
      <form onSubmit={enviarDatos}>
        <TextField
          name="NombreArea"
          label="Nombre Area"
          defaultValue={areaSeleccionada.NombreArea}
          className={styles.inputMaterial}
          onChange={handleChange}
          required
        />
        <div align="right">
          <Button type="submit" color="primary">
            Editar
          </Button>
          <Button onClick={abrirCerrarModalEditar}>Cancelar</Button>
        </div>
      </form>
    </div>
  );
  return (
    <div>
      <Button onClick={abrirCerrarModalEditar} class="d-none" id="botonModal">
        <AddCircleIcon /> Editar Cliente
      </Button>
      <Modal open={modalEditar} onClose={abrirCerrarModalEditar}>
        {body}
      </Modal>
    </div>
  );
};

export default ModalEditarArea;
