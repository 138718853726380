import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditIcon from "@material-ui/icons/Edit";
import meis from "../../img/meischile.png";
import recicle from "../../img/aa.png";
import { AuthContext } from "../../auth/AuthContext";
import { baseURL } from "../../baseURL";
import html2pdf from "html2pdf.js/src";
import { Print } from "@material-ui/icons";
import firma from "../../img/JV.jpg";

const RegistroScreen = () => {
  const { registroId } = useParams();
  const { user } = useContext(AuthContext);
  const [registro, setRegistro] = useState([]);
  const [cliente, setCliente] = useState([]);

  const peticionGetRegistro = async () => {
    console.log("asdasd")
    axios
      .get(baseURL + "registro.php?IdRegistro=" + registroId)
      .then((response) => {
        console.log(response);
        if (response.data.length === 0) {
          setRegistro("nodatos");
        } else {
          setRegistro(response.data[0]);
        }
      });
  };

  const history = useHistory();
  const retroceder = () => {
    history.goBack();
  };

  useEffect(async () => {
    await peticionGetRegistro();
  }, []);
  const doc = document.getElementById("doc");
  const imprimir = () => {
    let opt = {
      margin: 0,
      filename: "Registro " + registro.RutCliente + ".pdf",
      image: { type: "jpeg", quality: "0.98" },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    doc.classList.add("sizea4");
    html2pdf(doc, opt).then(() => {
      doc.classList.remove("sizea4");
    });
  };
  return (
    <>
      <div className="container ">
        <div className="row  mt-3 ">
          <div className="col d-flex justify-content-end">
            <button className="btn btn-light" onClick={imprimir}>
              <Print />
              Imprimir
            </button>
          </div>
        </div>
      </div>
      <div className="container mt-4 " id="doc">
        <div className="row d-flex justify-content-center ">
          <div className="col-12 col-md-12 col shadow-sm bg-white rounded ">
            {
              <div className="row">
                <div className="col-3 d-flex align-items-center ">
                  <img src={meis} alt="" className="img-fluid" width="150" />
                </div>
                <div className="col-6 d-flex justify-content-center">
                  <div>
                    <p className="m-0 text-center">Meis SPA</p>
                    <p
                      className="m-0 text-center font-weight-bold"
                      align="center"
                    >
                      MINING ENGINEERING INTEGRAL SERVICES SpA
                    </p>
                    <p className="m-0 text-center">
                      Resolución Exenta: 1838/2021 <br /> Teléfono de Contacto:
                      +56 9 97260302
                    </p>
                  </div>
                </div>
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <img
                    src={recicle}
                    alt=""
                    className="image-fluid"
                    height="100"
                  />
                </div>
              </div>
            }
            <div className="row">
              <div className="col d-flex justify-content-end">
                <span>{registro.FechaRegistro}</span>
              </div>
            </div>
            <div className="row d-flex justify-content-center ">
              <div className="col-10">
                <h1 className="m-0 text-center h3">CERTIFICADO</h1>

                <table class="table p-0">
                  <tbody>
                    <tr>
                      <th className="text-center p-1">
                        <h3 className="m-0 h4">Datos Cliente</h3>{" "}
                      </th>
                    </tr>
                    <tr>
                      <td scope="row" className="p-1">
                        <strong>Razón Social: </strong>
                        {registro.RazonSocial}
                      </td>
                    </tr>
                    <tr>
                      <td scope="row" className="p-1">
                        <strong>Representante Legal:</strong>{" "}
                        {registro.Representante}
                      </td>
                    </tr>
                    <tr>
                      <td scope="row" className="p-1">
                        <strong>R.U.T:</strong>
                        {registro.RutCliente}
                      </td>
                    </tr>
                    <tr>
                      <td scope="row" className="p-1">
                        <strong>Giro:</strong> {registro.Giro}
                      </td>
                    </tr>
                    <tr>
                      <td scope="row" className="p-1">
                        <strong>Dirección:</strong> {registro.DireccionCliente}
                      </td>
                    </tr>
                    <tr>
                      <td scope="row" className="p-1">
                        <strong>Teléfono:</strong> {registro.TelefonoCliente}
                      </td>
                    </tr>
                    <tr>
                      <td scope="row" className="p-1">
                        <strong>Email:</strong> {registro.EmailCliente}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-10">
                <table class="table">
                  <tbody>
                    <tr>
                      <th className="text-center p-0">
                        <h3 class="h4">Tratamientos Aplicados</h3>
                      </th>
                    </tr>
                    <tr>
                      <th className="text-center">SANITIZACIÓN</th>
                    </tr>
                    <tr>
                      <td scope="row">
                        <strong>Ingrediente Activo:</strong>{" "}
                        {registro.IngredienteActivo}
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">
                        <strong>Concentración:</strong> {registro.Concentracion}
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">
                        <strong>Registro Sanitario:</strong>{" "}
                        {registro.RegistroSanitario}
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">
                        <strong>Modo Acción: </strong> {registro.ModoAccion}
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">
                        <strong>Formulación: </strong> {registro.Formulacion}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row d-flex justify-content-center">
              <div className="col-10">
                <table class="table">
                  <tbody>
                    <tr>
                      <th className="text-center">AREAS TRATADAS</th>
                    </tr>
                    <tr>
                      <td scope="row">
                        <p className="m-0">{registro.NombreArea}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row ">
              <div className="col d-flex justify-content-center flex-column align-items-center">
                <img src={firma} alt="" width="100" />
                <span>José Luis Véliz Véliz</span>
                <strong>REPRESENTANTE TÉCNICO MEIS SpA</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistroScreen;
