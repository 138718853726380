import React, { useContext } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { AuthContext } from "../../auth/AuthContext";
import { types } from "../../types/types";
import meis from "../../img/meischile.png";
export const Navbar = () => {
  const { dispatch, user } = useContext(AuthContext);
  const history = useHistory();

  const handleLogout = () => {
    history.replace("/login");
    dispatch({
      type: types.logout,
    });
  };

  return (
    <nav className="navbar navbar-expand-sm navbar-dark bg-dark ">
      <div className="container">
        <div className="d-flex">
          <Link className="navbar-brand" to="/">
            <img src={meis} alt="" width="100" /> MeisChile
          </Link>

          {user[0].FK_ID_TIPO_USUARIO == 3 ? (
            <div className="navbar-collapse">
              <div className="navbar-nav">
                <NavLink
                  activeClassName="active"
                  className="nav-item nav-link"
                  exact
                  to="/clientes"
                >
                  Clientes
                </NavLink>
              </div>
            </div>
          ) : null}
         
        </div>
        <div className="d-flex">
          <div className=" w-100 order-3 dual-collapse2">
            <ul className="navbar-nav ml-auto">
              <button
                type="button"
                class="btn nav-item nav-link"
                onClick={handleLogout}
              >
                Salir
              </button>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};
